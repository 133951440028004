<template lang="pug">
div
  slot(name="activator" :accept="accept")
    v-btn(small color='primary' 
      @click="accept" 
      :disabled="disabledAccept"
      outlined
    ) accept/kdr
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin],

  inject: ['svc'],

  props: {
    activeItems: {
      required: true,
      type: Array
    },
    disabled: Boolean
  },

  computed: {
    disabledAccept() {
      if (this.disabled) return true
      if (!this.activeItems.length) return true
      return false
    },
  },

  methods: { 
    Svc() {
      return this.svc()
    },

    acceptHandler(cbrUser) {
      this.accept(cbrUser)
    },

    async accept() {
      try {
        let ids = this.activeItems.map(item => item.ID)
        await this.Svc().accept(ids)
        this.$notify({text: 'Started accepting/searching KDR', type: 'success'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    }
  }
}
</script>